<template>
  <Template
    v-bind="{
      ...params,
      isLoading,
    }"
    @onSubmit="() => onSend()"
    @goTo="(path) => $router.push(path)"
	@onBack="() => $router.go(-1)"
    @goHome="() => $router.push('/')"
  />
</template>
<script>
import Template from "../components/templates/ResetPassword/ResetPassword";
import { c } from "@/components/constants.js";
import { Settings } from "../../settings";

export default {
  components: {
    Template,
  },
  methods: {
    async onSend() {
      console.log(this.params);
      if (this.isLoading) return false;
      const forms = this.params.form.forms;
      console.log(forms, "forms ====");
      if (!forms[0]?.value) {
        this.actions.alert.showError({
          message: "Please insert email address!",
        });
        return false;
      }
      try {
        this.isLoading = true;
        const { triggerPasswordReset } =
          await this.actions.user.triggerPasswordReset({
            email: forms[0]?.value?.toLowerCase(),
            domain: Settings.mainDomain,
          });
        console.log(triggerPasswordReset);
        if (triggerPasswordReset) {
          this.$router.push(
            "/reset-password-email-sent?email=" + forms[0]?.value
          );
          this.actions.alert.showSuccess({
            message: "Email sent successfully!",
          });
        } else {
          this.actions.alert.showError({ message: "The email doesn't exist." });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      params: c.resetPassword,
      isLoading: false,
    };
  },
};
</script>
